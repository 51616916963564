<template>
  <section>
    <h2>
      <span>Employment History</span>
    </h2>
    <employment-item
      v-for="job in this.employment"
      :key="job.company"
      :job="job"
    />
  </section>
</template>

<script>
  import EmploymentItem from '../../components/Profile/EmploymentItem';
  import { employment } from '../../data/constants';
  export default {
    name: 'Employment',
    components: {
      EmploymentItem,
    },
    data() {
      return {
        employment,
      }
    }
  }
</script>

<style scoped lang="scss">
  h2 {
    text-align: center;
    margin: 50px 0 100px;

    span{
      padding: 6px 8px 0;
      display: inline-block;
      line-height: 19px;
    }
  }

  section {
    max-width: 700px;
    margin: 0 auto;

    @media (max-width: $table-screen) {
      padding: 0 30px;
      margin: initial;
    }
  }

</style>

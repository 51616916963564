<template>
  <section>
    <h2>
      <span>Key Skills</span>
    </h2>
    <skill-row :stack="this.stackTop" class="mb-60"/>
    <skill-row :stack="this.stackBot" />
  </section>
</template>

<script>
  import SkillRow from './SkillRow';
  import { stackTop, stackBot } from '../../data/constants';
  export default {
    name: 'Skills',
    data() {
      return {
        stackTop,
        stackBot,
      };
    },
    components: {
      SkillRow,
    }
  }
</script>

<style scoped lang="scss">
  section{
    padding: 50px 0;
    background: $white-gray;
  }

  .mb-60{
    margin-bottom: 60px;
  }

  h2{
    text-align: center;
    margin-bottom: 50px;

    span{
      padding: 6px 8px 0;
      display: inline-block;
      line-height: 19px;
    }
  }

  @media (max-width: 660px) {
    section {
      padding: 50px 30px 10px;
    }
    .mb-60 {
      margin-bottom: 0;
    }
  }
</style>

<template>
  <form ref="emailForm" @submit.prevent="onSubmit">
    <custom-input
      label="Email"
      type="email"
      :value="this.email"
      @on-input="(val) => this.email = val"
      :required="true"
    />
    <custom-input
      label="Full Name"
      type="text"
      :value="this.name"
      @on-input="(val) => this.name = val"
    />
    <custom-input
      label="Tell me about your project, timeline and budget"
      class="mb-30"
      type="text"
      :value="this.message"
      @on-input="(val) => this.message = val"
      :required="true"
    />
    <Button :disabled="isDisabled">
      Send message
    </Button>
  </form>
</template>

<script>
  import CustomInput from '../CustomInput';
  import Button from '../Button';
  import { init, send } from 'emailjs-com';
  import { userID, serviceId, templateId } from '../../data/constants';
  import { showNotification } from "../../data/services";

  export default {
    name: 'EmailMeForm',
    components: {
      CustomInput,
      Button
    },
    methods: {
      async onSubmit() {
        try {
          const { email, name, message } = this;
          const templateParams = { email, name, message };
          this.disabled = true;
          await send(serviceId, templateId, templateParams);
          showNotification('Message has been sent successfully', 'success');
        } catch {
          showNotification('Something went wrong', 'error');
        } finally {
          this.disabled = false;
          this.$refs.emailForm.reset();
        }
      },
    },
    data() {
      return {
        email: '',
        name: '',
        message: '',
        disabled: false,
      }
    },
    computed: {
      isDisabled() {
        return this.disabled;
      }
    },
    created() {
      init(userID);
    },
  }
</script>

<style scoped lang="scss">
  .mb-30{
    margin-bottom: 30px;
  }

  button {
    margin: 0 auto;
  }
</style>
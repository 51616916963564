<template>
  <div class="item">
    <div class="main">
      <div class="position">{{job.position}}</div>
      <div :class="['company', underlineClass]" @click="handleClick">
        {{job.company}}
      </div>
    </div>
    <div class="meta">
      <div class="duration">{{job.startDate}} - {{job.endDate}}</div>
      <div>{{job.location}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EmploymentItem',
    props: ['job'],
    methods: {
      handleClick() {
        if (this.job.link) {
          window.open(this.job.link, '_blank');
        }
      },
    },
    computed: {
      underlineClass() {
        return this.job.link ? 'underline' : '';
      },
    }
  }
</script>

<style scoped lang="scss">
  .item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-separator;
    display: flex;
    align-items: baseline;

    &:last-child {
      border-bottom: none ;
    }
  }

  .main {
    width: 250px;
  }

  .meta {
    color: $light-gray;
  }

  .duration {
    margin-bottom: 10px;
  }

  .company {
    font-size: 18px;
    font-weight: 600;

    &.underline {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .position {
    margin-bottom: 10px;
  }

  @media (max-width: $mobile-screen) {
    .item {
      display: block;
    }

    .main {
      width: initial;
      margin-bottom: 25px;
    }
  }
</style>
<template>
  <section class="profile-head">
    <div class="photo">
      <img src="/images/photo.jpg" />
    </div>
    <div class="name">
      Danil Astakhov
    </div>
    <div class="location">
      <img src="/images/georgia.png" class="img"/>
      Tbilisi, Georgia
    </div>
    <div class="intro">
      I am a full-stack developer with over {{this.yearsOfExperience}}
      years' of experience in various
      <br/>
      projects from simple applications to high scale systems
    </div>
    <social-networks />
  </section>
</template>

<script>
  import SocialNetworks from "../SocialNetworks";
  const startYear = 2016;
  export default {
    name: 'ProfileHead',
    components: {
      SocialNetworks
    },
    data() {
      return {
        yearsOfExperience: new Date().getFullYear() - startYear,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .profile-head{
    text-align: center;
    padding-top: 70px;

    .name, .location {
      font-size: 18px;
    }

    .location {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 25px;
        margin-right: 10px;
      }
    }

    .name{
      font-weight: 900;
      margin-bottom: 10px;
    }

    .location{
      color: #A3A3A3;
      margin-bottom: 20px;
    }

    .intro{
      line-height: 27px;
      margin-bottom: 40px;
    }

    .photo {
      margin-bottom: 25px;
      img{
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }
    }
  }

  @media (max-width: 768px) {
    .profile-head {
      padding: 0 30px 30px;
    }
  }
</style>

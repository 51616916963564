<template>
  <div>
    <profile-head />
    <section class="description">
      <ul class="text">
        Provide my Clients such services as:
        <br/>
        <li>project development from scratch - full cycle of
          development from planning to launch and future technical support;
        </li>
        <li>MVP development - we will analyze your project and market and
          choose functionality to start the development with;
        </li>
        <li>
          working with an existing team - I'm communicative and can
          easily get into the project and adapt to the tasks;
        </li>
        <li>
          leading a team - working according to Agile methodologies
        </li>
      </ul>
    </section>
    <skills />
    <employment />
    <email-me />
  </div>
</template>

<script>
  import ProfileHead from '../components/Profile/ProfileHead';
  import Skills from '../components/Profile/Skills';
  import Employment from '../components/Profile/Employment';
  import EmailMe from "../components/Profile/EmailMe";
  export default {
    name: 'Profile',
    components: {
      ProfileHead,
      Skills,
      Employment,
      EmailMe,
    },
  }
</script>

<style scoped lang="scss">
  .description{
    padding: 50px 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }

  .text {
    width: 500px;
    margin: 0 auto;
    font-size: 17px;
    list-style: inside;
  }

  @media (max-width: $table-screen) {
    .description {
      padding: 30px 30px 70px;
    }
  }

  @media (max-width: 550px) {
    .text {
      width: 100%;
    }
  }
</style>

<template>
  <div class="scopes-row">
    <div
      v-for="tech in stack"
      :key="tech.header"
      class="col"
    >
      <div class="head">{{tech.header}}</div>
      <div
        class="skill-item"
        v-for="skill in tech.skills.split(',')"
        :key="skill"
      >
        {{skill}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SkillRow',
    props: ['stack'],
  }
</script>

<style scoped lang="scss">
  .scopes-row{
    display: flex;
    justify-content: space-evenly;

    &:first-child{
      margin-bottom: 60px;
    }
  }

  .head{
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 20px;
    width: 220px;
    height: 45px;
  }

  .skill-item{
    font-size: 18px;
    line-height: 32px;
  }

  @media (max-width: $table-screen) {
    .scopes-row{
      display: block;
      text-align: center;
    }

    .col {
      margin-bottom: 50px;
    }

    .head {
      margin-bottom: 0;
      width: initial;
    }
}

</style>
<template>
  <section>
    <div class="section-head">
      <h2>Let’s Work Together</h2>
      <p>How can I help you?</p>
    </div>
    <email-me-form />
  </section>
</template>

<script>
  import EmailMeForm from './EmailMeForm';
  export default {
    name: 'EmailMe',
    components: {
      EmailMeForm,
    },
  }
</script>

<style scoped lang="scss">
  section {
    padding: 90px 0;
    text-align: center;
    background: $white-gray;
  }

  .section-head {
    margin-bottom: 50px;
  }

  h2{
    font-size: 30px;
  }

  p{
    font-size: 18px;
    color: $gray;
  }

  @media (max-width: $table-screen) {
    section {
      padding: 30px;
    }
  }
</style>

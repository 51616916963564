<template>
  <div class="custom-input">
    <label>{{label}}</label>
    <input
      :type="type"
      :value="this.val"
      autocomplete="new-password"
      @input="handleChanged"
      :required="required"
    />
  </div>
</template>

<script>
  export default {
    name: 'CustomInput',
    props: [
      'label',
      'value',
      'type',
      'required',
    ],
    data() {
      return {
        val: '',
      }
    },
    methods: {
      handleChanged(e) {
        this.$emit('on-input', e.target.value);
      },
    },
    watch: {
      value(newVal) {
        this.val = newVal;
      }
    }
  }
</script>

<style scoped lang="scss">
  .custom-input{
    max-width: 650px;
    min-width: 250px;
    margin: 0 auto 30px;
    text-align: left;
  }

  label{
    display: block;
    font-size: 14px;
    margin-bottom: 15px;
    color: $gray;
  }

  input{
    width: 100%;
    height: 35px;
    border: none;
    border-bottom: 1px solid $gray;
    background: transparent;
    font-size: 20px;
  }
</style>